import order1 from "../images/website/home/order1.png";
import order2 from "../images/website/home/order2.png";
import order3 from "../images/website/home/order3.png";
import order4 from "../images/website/home/order4.png";
import order5 from "../images/website/home/order5.png";
import order6 from "../images/website/home/order6.png";
import halal1 from "../images/website/home/halal1.png";
import halal2 from "../images/website/home/halal2.png";
import halal3 from "../images/website/home/halal3.png";
import mar1 from "../images/website/home/mar1.png";
import mar2 from "../images/website/home/mar2.png";
import mar3 from "../images/website/home/mar3.png";
import mar4 from "../images/website/home/mar4.png";
import mar5 from "../images/website/home/mar5.png";
import mar6 from "../images/website/home/mar6.png";
import part1 from "../images/website/home/part1.png";
import part2 from "../images/website/home/part2.png";
import know1 from "../images/website/home/know1.png";
import know2 from "../images/website/home/know2.png";
import know3 from "../images/website/home/know3.png";
import team1 from "../images/website/aboutUs/team1.jpg";
import team2 from "../images/website/aboutUs/team2.jpg";
import team3 from "../images/website/aboutUs/team3.jpg";
import team4 from "../images/website/aboutUs/team4.png";
import moreBlog1 from "../images/website/blog/moreBlog1.png";
import moreBlog2 from "../images/website/blog/moreBlog2.png";
import moreBlog3 from "../images/website/blog/moreBlog3.png";
import event1 from '../images/website/event/event1.png';
import event2 from '../images/website/event/event2.png';
import event3 from '../images/website/event/event3.png';
import event4 from '../images/website/event/event4.png';
import event5 from '../images/website/event/event5.png';
import event6 from '../images/website/event/event6.png';
import tab1 from '../images/website/appFeature/tab1.png';
import tab2 from '../images/website/appFeature/tab2.png';
import tab3 from '../images/website/appFeature/tab3.png';
import tab4 from '../images/website/appFeature/tab4.png';
import tab5 from '../images/website/appFeature/tab5.png';
import tab6 from '../images/website/appFeature/tab6.png';
import tab7 from '../images/website/appFeature/tab7.png';
import tab8 from '../images/website/appFeature/tab8.png';
import tab9 from '../images/website/appFeature/tab9.png';
import tab10 from '../images/website/appFeature/tab10.png';

const orderList = [
  {
    img: order1,
    header: "Restaurants",
    text: "21 Store"
  },
  {
    img: order2,
    header: "Butchers",
    text: "32 Store"
  },
  {
    img: order3,
    header: "Ingredients",
    text: "4 Store"
  },
  {
    img: order4,
    header: "Pharmacies",
    text: "32 Store"
  }
]
const halalOption = [
  {
    link: '#',
    name: 'Restaurants'
  },
  {
    link: '#',
    name: 'Butchers'
  },
  {
    link: '#',
    name: 'Ingredients'
  },
  {
    link: '#',
    name: 'Pharmacy'
  }
]
const halalBox = [
  {
    bgImg: halal1,
    discount: "4% off your order",
    head: "Butchers",
    para: "The Halal Guys"
  },
  {
    bgImg: halal2,
    discount: "4% off your order",
    head: "Restaurant",
    para: "Grand Ai Cafe"
  },
  {
    bgImg: halal3,
    discount: "4% off your order",
    head: "Pharmacy",
    para: "Guardian Pharmacy"
  },
  {
    bgImg: halal1,
    discount: "4% off your order",
    head: "Butchers",
    para: "The Halal Guys"
  }
]
const marItem = [
  {
    img: mar1,
    text: "Marketplace",
    status: "Coming Soon"
  },
  {
    img: mar2,
    text: "Healthly Food",
    status: "Coming Soon"
  },
  {
    img: mar3,
    text: "Travels",
    status: "Coming Soon"
  },
  {
    img: mar4,
    text: "Food Share",
    status: "Coming Soon"
  },
  {
    img: mar5,
    text: "Home Chef",
    status: "Coming Soon"
  },
  {
    img: mar6,
    text: "Muslim Community",
    status: "Coming Soon"
  },
  // {
  //   img: order5,
  //   text: "Halal Web",
  //   status: "Coming Soon"
  // },
  // {
  //   img: order6,
  //   text: "Quran Learning",
  //   status: "Coming Soon"
  // },
]
const signData = [
  {
    img: part1,
    text1: "Earn more with lower fees",
    text2: "Signup as a business",
    main: "Join us",
    btn: "Get Started",
    href: "/register"
  },
  {
    img: part2,
    text1: "Avail exclusive perks",
    text2: "Be our delivery partner",
    main: "Become a delivery partner",
    btn: "Get Started",
    href: "/rider-register"
  },
]

const knowBtn = [
  {
    link: "#",
    name: "How does Aimhalal work?"
  },
  {
    link: "#",
    name: "What payment methods are accepted?"
  },
  {
    link: "#",
    name: "Can I track my order in real-time?"
  },
  {
    link: "#",
    name: "Are there any special discounts or promotions available?"
  },
  {
    link: "#",
    name: "Is Aimhalal available in my area?"
  },
]
const knowCard = [
  {
    head: "Place an Order!",
    img: know1,
    text: "Place order through our Mobile app"
  },
  {
    head: "Track Progress",
    img: know2,
    text: "Your can track your order status with delivery time"
  },
  {
    head: "Get your Order!",
    img: know3,
    text: "Receive your order at a lighting fast speed!"
  },
]
const counterBanner = [
  {
    value: "546",
    name: "Registered Riders",
    border: "1px solid #FAD700"
  },
  {
    value: "789900",
    name: "Orders Delivered",
    border: "1px solid #FAD700"
  },
  {
    value: "690",
    name: "Halal Vendors Partnered",
    border: "1px solid #FAD700"
  },
  {
    value: "17457",
    name: "Halal items",
  },
]
const teamMember = [
  {
    img: team1,
    name: "COO/CPO",
    des: "Muhammad Imran",
    details: "10+ years of experience in Entrepreneurship and business formation and planning"
  },
  {
    img: team2,
    name: "CEO/CTO",
    des: "Shahbaz Saeed",
    details: "10+ years of experience in Entrepreneurship and business formation and planning"
  },
  {
    img: team3,
    name: "Business Lead",
    des: "Tehmina A Chaudhry",
    details: "10+ years of experience in Entrepreneurship and business formation and planning"
  },
  {
    img: team4,
    name: "Business/Community Catalyst",
    des: "Sumaira Saif",
    details: "10+ years of experience in Entrepreneurship and business formation and planning"
  },
]
const moreBlog = [
  {
    img: moreBlog1,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleblog"
  },
  {
    img: moreBlog2,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleblog"
  },
  {
    img: moreBlog3,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleblog"
  },
]
const eventCard = [
  {
    img: event1,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleevent"
  },
  {
    img: event2,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleevent"
  },
  {
    img: event3,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleevent"
  },
  {
    img: event4,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleevent"
  },
  {
    img: event5,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleevent"
  },
  {
    img: event6,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleevent"
  },
]
const moreEvent = [
  {
    img: event4,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleevent"
  },
  {
    img: event5,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleevent"
  },
  {
    img: event6,
    date: "March 31, 2022",
    text: "Embracing a Halal Lifestyle with Aimhalal: Your Gateway to a Vibrant Muslim Community",
    href: "/singleevent"
  },
]
const tabList = [
  {
    img: tab1,
    text: "Groceries"
  },
  {
    img: tab2,
    text: "Home-Chef"
  },
  {
    img: tab3,
    text: "Directories"
  },
  {
    img: tab4,
    text: "Buthchers"
  },
  {
    img: tab5,
    text: "Events"
  },
  {
    img: tab6,
    text: "Eateries"
  },
  {
    img: tab9,
    text: "Funeral Service"
  },
  {
    img: tab10,
    text: "Culture"
  },
  {
    img: tab7,
    text: "Quran Learning"
  },
  {
    img: tab8,
    text: "Food Share"
  },
]
const headItem = [
  {
    name: "Home",
    href: "/"
  },
  {
    name: "About Us",
    href: "/aboutus"
  },
  {
    name: "App Features",
    href: "#",
    children: [
      {
        label: 'Groceries',
        href: '/app-feature/groceries',
      },
      {
        label: 'Home-Chef',
        href: '/app-feature/home-chef',
      },
      {
        label: 'Directories',
        href: '/app-feature/directories',
      },
      {
        label: 'Butchers',
        href: '/app-feature/butchers',
      },
      {
        label: 'Events',
        href: '/app-feature/events',
      },
      {
        label: 'Eateries',
        href: '/app-feature/eatries',
      },
      {
        label: 'Funeral Service',
        href: '/app-feature/funeral-service',
      },
      {
        label: 'Culture',
        href: '/app-feature/culture',
      },
      {
        label: 'Quran Learning',
        href: '/app-feature/quran-learning',
      },
      {
        label: 'Food Share',
        href: '/app-feature/food-share',
      },
      {
        label: 'Halal Web',
        href: '/app-feature/halal-web',
      }
    ],
  },
  {
    name: "Privacy Policy",
    href: "/privacypolicy"
  },
  {
    name: "Blogs",
    href: "/blog"
  },
  {
    name: "Events",
    href: "/event"
  },
  {
    name: "Contact Us",
    href: "/contactus"
  },
]

export { orderList, halalOption, halalBox, marItem, signData, knowBtn, knowCard, counterBanner, teamMember, moreBlog, eventCard, moreEvent, tabList, headItem }