import { Stack, Text, Box, Image, Button, OrderedList, ListItem } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { Link } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
// Components
import HalalAdd from "../../components/Website/Home/halalAdd";
import SignCard from "../../components/Website/Home/signCard";
import CounterBox from "../../components/Website/Home/counterBox";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Header from "../../components/Website/Header/header";
import Footer from "../../components/Website/Footer/Footer";
// Images
import tabmain from '../../assets/images/website/appFeature/tabmain.png'
import funeral from '../../assets/images/website/appFeature/funeral.png'
import culture from '../../assets/images/website/appFeature/culture.png'
import chef from '../../assets/images/website/appFeature/chef.png'
import directories from '../../assets/images/website/appFeature/directories.png'
import butch from '../../assets/images/website/appFeature/butch.png'
import event from '../../assets/images/website/appFeature/event.jpg'
import eat from '../../assets/images/website/appFeature/eat.jpg'
import quran from '../../assets/images/website/appFeature/quran.jpg'
import share from '../../assets/images/website/appFeature/share.png'
import feature1 from '../../assets/images/website/appFeature/feature1.png';
import BlackOver from '../../assets/images/icon/black.png';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg';
import { signData, tabList } from "../../assets/data/data";
import { useEffect } from "react";

const AppFeature = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={"Directories"} />
            </Box>

            {/* <Box p={"40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Stack direction={{ base: "column", lg: "row" }} alignItems={"Center"} justifyContent={"space-between"}>
                        <Box>
                            <Image src={feature1} w={"646px"} />
                        </Box>
                        <Box maxW={"629px"}>
                            <Text fontSize={"26px"} fontWeight={"600"} mb={"20px"}>AIMHalal Ambition</Text>
                            <Text fontSize={"16px"} mb={"30px"}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ndesktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</Text>
                            <Button as={Link} to={"#"} bg={"primaryYellow.100"} bgImage={yellowBtnTexture} bgPos={'center'} bgSize={'cover'} bgRepeat={'no-repeat'} p={'0px 30px'} transform={"skew(-8deg)"} border={"1px solid #28551A"} _hover={{ bg: "none" }}>
                                <Text as={'span'} transform={"skew(8deg)"} color={"#28551A"}>Read More</Text>
                            </Button>
                        </Box>
                    </Stack>
                </Container>
            </Box> */}

            <Box bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box p={"30px 0px"}>
                        <Stack direction={{ base: "column", xl: "row" }} flexDirection={{ base: "column-reverse", xl: 'initial' }} alignItems={"center"} justifyContent={"space-between"}>
                            <Box maxW={"607px"}>
                                <Text color={'primaryGreen.100'} fontSize={"24px"} fontWeight={"700"} letterSpacing={"-0.48px"} mb={"20px"}>Halal Directories</Text>
                                <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} mb={"20px"}>Navigate the world of Halal businesses effortlessly through our Directories. Our comprehensive directory is your go-to resource for discovering Halal-certified services, shops, and professionals in your area. Trust in the authenticity of our listings, ensuring that every business you connect with shares your commitment to the highest Halal standards. Experience convenience, trust, and quality in every search with AIMHalal.</Text>
                                <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} fontWeight={"600"} mb={"20px"}>Here's what you can expect from our Halal Groceries feature:</Text>
                                {/* <OrderedList fontSize={"15px"} letterSpacing={"-0.24px"}>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Find Halal Groceries Nearby**: With AIMHalal's Halal Groceries feature, you can effortlessly locate Halal grocery stores in your vicinity. Our user-friendly interface allows you to search for Halal groceries based on your current location, making it convenient to find the nearest stores that offer a wide range of Halal products.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Explore a Variety of Products**: Our Halal Groceries feature not only helps you find Halal grocery stores, but it also enables you to explore the diverse range of Halal products they offer. Whether you're looking for Halal-certified meats, dairy products, pantry essentials, or international ingredients, AIMHalal makes it easy for you to discover and access a wide array of Halal options.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Read Reviews and Ratings**: Making informed decisions about your grocery shopping experience is essential. AIMHalal's Halal Groceries feature includes reviews and ratings from fellow users, allowing you to get insights into the quality and service of different grocery stores. You can benefit from the experiences and recommendations of the AIMHalal community, ensuring a satisfying shopping experience.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Contribute to the Community**: AIMHalal is a community-driven platform, and we encourage you to contribute your own feedback and reviews about the Halal grocery stores you visit. Your insights and recommendations will help others in the community make informed choices and foster a collaborative environment for Halal grocery shopping.</ListItem>
                                </OrderedList> */}
                                <OrderedList fontSize={"16px"} letterSpacing={"-0.24px"}>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>Wide Range of Categories</b>:<br /> Explore a variety of categories for all your needs.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>Verified Halal Certification</b>:<br /> Trust in our products with verified Halal certification.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>User-Friendly Interface</b>:<br /> Enjoy easy navigation with our user-friendly interface.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>Supporting Halal Businesses</b>:<br /> Support Halal businesses directly through our platform.</ListItem>
                                    {/* <ListItem color={'primaryGreen.100'}>Experience the convenience and peace of mind that AIMHalal's Halal Markets feature offers. Join our growing community and explore a world of Halal products right at your fingertips. Download the AIMHalal app today on Android or iOS and unlock the possibilities of Halal living like never before. Together, let's embrace a Halal lifestyle with AIMHalal's Halal Markets feature.</ListItem> */}
                                </OrderedList>
                                {/* <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} fontWeight={"600"} mb={"20px"}>Experience the convenience of finding Halal groceries near you with AIMHalal's Halal Groceries feature. Discover a variety of Halal products, read reviews, and make your grocery shopping a seamless and enjoyable experience.

                                    <br />
                                    Download the AimHalal app today on Android or iOS and unlock the ease of finding Halal groceries at your fingertips.
                                    <br />
                                    Together, let's simplify your Halal grocery shopping with AimHalal's Halal Groceries feature and embrace a wholesome Halal lifestyle.</Text> */}
                            </Box>
                            <Box>
                                <Image src={directories} />
                            </Box>
                        </Stack>
                    </Box>
                </Container>
            </Box>

            <Box p={"100px 0px 40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <HalalAdd />
                    </Box>
                </Container>
            </Box>

            <Box p={"30px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <Stack
                            direction={{ base: "column", xl: "row" }}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            {signData.map((val, key) => {
                                return (
                                    <SignCard img={val.img} text1={val.text1} text2={val.text2} main={val.main} btn={val.btn} href={val.href} key={key} />
                                )
                            })
                            }
                        </Stack>
                    </Box>
                    <Box pt={"40px"}>
                        {/* <CounterBox /> */}
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default AppFeature;