import { Stack, Text, Box, Image, Input, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, Heading, useDisclosure, useToast, Icon } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import React, { useEffect, useRef, useState } from 'react';
import { FaChevronRight, FaChevronLeft, FaCheckCircle } from "react-icons/fa";
import Logo from '../../assets/images/logo/logo.svg'
import setting from '../../assets/images/website/setting.svg'
import secure from '../../assets/images/website/secure.svg'
import bond from '../../assets/images/website/bond.svg'
import food from '../../assets/images/website/food.svg'
import shapeb from '../../assets/images/icon/black.png'
// components
import OrderCard from "../../components/Website/Home/orderCard";
import HalalAdd from "../../components/Website/Home/halalAdd";
import SignCard from "../../components/Website/Home/signCard";
import CounterBox from "../../components/Website/Home/counterBox";
import Header from "../../components/Website/Header/header";
import Footer from "../../components/Website/Footer/Footer";
// Images
import homeBg from '../../assets/images/website/home/homeBg.png';
import herotext from '../../assets/images/website/home/herotext.png';
import BlackOver from '../../assets/images/icon/black.png';
import WhiteOver from '../../assets/images/icon/white.png';
import Down1 from '../../assets/images/website/home/down1.png';
import Down2 from '../../assets/images/website/home/down2.png';
import { orderList, halalOption, halalBox, marItem, signData, knowBtn, knowCard } from "../../assets/data/data";
import greenBtnTexture from '../../assets/images/greenBtnTexture.svg'
import { GET, POST } from "../../utilities/ApiProvider";

import food2 from '../../assets/images/website/how/food.png'
import download from '../../assets/images/website/how/download.png'
import resiter from '../../assets/images/website/how/register.png'
import res from '../../assets/images/website/how/restaurant.png'
import eve from '../../assets/images/website/how/event.png'
import pro from '../../assets/images/website/how/pro.png'
import muslim from '../../assets/images/website/how/muslim.png'
import feedback from '../../assets/images/website/how/feedback.png'
import about1 from '../../assets/images/website/aboutUs/homeabout1.png';
import about2 from '../../assets/images/website/aboutUs/homeabout2.png';

import appImg1 from '../../assets/images/appFeatureSec/1.png'
import appImg2 from '../../assets/images/appFeatureSec/2.png'
import appImg3 from '../../assets/images/appFeatureSec/3.png'
import appImg4 from '../../assets/images/appFeatureSec/4.png'
import appImg5 from '../../assets/images/appFeatureSec/5.png'
import appImg6 from '../../assets/images/appFeatureSec/6.png'
import AppFeatureCard from "../../components/Website/Home/AppFeatureCard";
import { imgUrl } from "../../utilities/config";
import qr from '../../assets/images/qr.jpg'

export default function Home() {
  const toast = useToast();
  const [storeTypes, setStoreTypes] = useState([]);
  const [banners, setBanners] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  // 1st Swiper 
  const swiperRefCat = useRef(null);
  const handleNextCat = () => {
    if (swiperRefCat.current && swiperRefCat.current.swiper) {
      swiperRefCat.current.swiper.slideNext();
    }
  };
  const handlePrevCat = () => {
    if (swiperRefCat.current && swiperRefCat.current.swiper) {
      swiperRefCat.current.swiper.slidePrev();
    }
  };
  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  // 2nd Swiper 
  const swiperRef2 = useRef(null);
  const handleNext2 = () => {
    if (swiperRef2.current && swiperRef.current.swiper) {
      swiperRef2.current.swiper.slideNext();
    }
  };
  const handlePrev2 = () => {
    if (swiperRef2.current && swiperRef.current.swiper) {
      swiperRef2.current.swiper.slidePrev();
    }
  };

  const submitNewsLetterPolicy = async () => {
    setIsLoading(true);
    let form = document.getElementById('newsLetterPopForm');
    let data = new FormData(form);
    try {
      let res = await POST(`/web/news-letter`, data);
      toast({
        description: `News Letter Subscribed!`,
        status: res?.staus === 200 ? 'success' : 'error',
        isClosable: true,
        position: 'top-right',
        duration: 2500
      });
      onClose();
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        isClosable: true,
        position: 'top-right',
        duration: 2500
      })
    }
    setIsLoading(false);
  }

  const [knowSelectedBtn, setKnowSelectedBtn] = useState(true);
  const [chooseBtn, setChooseBtn] = useState([
    {
      name: 'Easy Settings',
      img: setting
    },
    {
      name: 'Fast & Secure',
      img: secure
    },
    {
      name: 'Community Bond',
      img: bond
    },
    {
      name: 'Secure & Halal Food',
      img: food
    },
  ]);
  const [howBtn, setHowBtn] = useState([
    {
      name: 'Download/Intall',
      img: download
    },
    {
      name: 'Register Account',
      img: resiter
    },
    {
      name: 'Explore Restaurants',
      img: res
    },
    {
      name: 'Discover Events',
      img: eve
    },
    {
      name: 'Locate Products',
      img: pro
    },
    {
      name: 'Halal Food Directory',
      img: food
    },
    {
      name: 'Muslim Community',
      img: muslim
    },
    {
      name: 'Give Your Feedback',
      img: feedback
    },
  ]);
  const appFeatureInfo = [
    {
      heading: 'Halal Restaurants',
      text: 'Halal restaurants serve dishes compliant with Islamic dietary laws, excluding pork and alcohol, offering diverse menus for halal-conscious diners.',
      img: appImg1
    },
    {
      heading: 'Nearby Halal Options',
      text: 'Explore nearby halal options offering a variety of flavorful dishes in adherence to Islamic dietary standards, excluding pork and alcohol.',
      img: appImg2
    },
    {
      heading: 'Favorite List',
      text: 'Easily save your favorite store and items for quick access. Enjoy a curated selection of must-haves at your fingertips for a seamless shopping experience."',
      img: appImg3
    },
    {
      heading: 'Ingredients',
      text: 'Halal: Permissible under Islamic dietary laws. Haram: Forbidden ingredients. Clarifying religious guidelines for dietary choices.',
      img: appImg4
    },
    {
      heading: 'Muslim Community',
      text: 'Engage in Muslim community events celebrating unity, culture, and charity. Join us for festivals, initiatives, and shared experiences that enrich and unite.',
      img: appImg5
    },
    {
      heading: 'Find what you need, fast.',
      text: 'Effortlessly sort items with our intuitive filter system. Enjoy a seamless browsing experience as you quickly find what you need.',
      img: appImg6
    },
  ]
  useEffect(() => {

    (async () => {
      let storesRes = await GET(`/admin/roles/vendor`);
      if (storesRes.status === 200) {
        setStoreTypes(storesRes.data);
      } else {
        toast({
          description: 'Unable to find store types, please check internet connection or try later!',
          status: 'error',
          position: 'top-right',
          duration: 3000
        })
      }
      let listOfCat = await GET(`/admin/roles/categorywithStoresCount`);
      if (listOfCat.status === 200) {
        setCategoryList(listOfCat?.data);
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
      try {
        let res = await GET(`/admin/banner/all`);
        if (res.status === 200) {
          setBanners(res?.data);
        } else {
          toast({
            description: res.message,
            status: 'error',
            position: 'top-right',
            duration: 2500
          });
        }

      } catch (err) {
        toast({
          description: err.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    })();

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    const timeoutId = setTimeout(() => {
      onOpen()
    }, 8000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Header />
      <Box bgImage={BlackOver}
        display={{ base: 'none', xl: 'block' }}
      >
        <Container
          maxW={{ base: "1366px", '2xl': "1440px" }}>
          <Swiper
            ref={swiperRef2}
            slidesPerView={1}
            spaceBetween={"20px"}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            loop={true}
            breakpoints={{
              375: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1366: {
                slidesPerView: 1,
                spaceBetween: 0,
              }
            }}
          >
            {
              marItem.map((val, key) => {
                return (
                  banners?.length > 0 ?
                    banners?.map(val => {
                      return (
                        <SwiperSlide className="desktopView" key={val?._id}>
                          <Box
                            bg={"#28551A"}
                            bgImage={`${imgUrl}/${val?.banner}`}
                            bgPosition={"center"}
                            bgRepeat={"no-repeat"}
                            bgSize={"cover"}
                            borderRadius={"10px"}
                            height={{ base: "140px", xl: "280px", '2xl': "320px" }}
                          ></Box>
                        </SwiperSlide>
                      )
                    }
                    )
                    :
                    < SwiperSlide key={key} >
                      <Box
                        bg={"#28551A"}
                        bgImage={homeBg}
                        bgPosition={"center"}
                        bgRepeat={"no-repeat"}
                        bgSize={"cover"}
                        borderRadius={"10px"}
                        height={{ base: "140px", xl: "280px", '2xl': "320px" }}
                      >
                        <Container maxW={"container.xl"}>
                          <Stack
                            h={{ base: "210px", xl: "510px", "2xl": "510px" }}
                            direction={"row"}
                            alignItems={{ base: "center", xl: "end", "2xl": "end" }}
                            justifyContent={{ base: "flex-end", xl: "flex-end", "2xl": "flex-end" }}
                            pb={{ base: "0px", xl: "40px", "2xl": "40px" }}
                          >
                            <Box maxW={"645px"}>
                              <Text
                                fontSize={{ base: "14px", xl: "26px", "2xl": "26px" }}
                                fontWeight={"700"}
                                lineHeight={"normal"}
                                color={"primaryYellow.100"}
                                mb={{ base: "10px", xl: "15px", "2xl": "15px" }}
                              >
                                Enjoy Halal Lifestyle with us
                              </Text>
                              <Image
                                src={herotext}
                                w={{ base: "200px", xl: "402px", "2xl": "402px" }}
                                h={{ base: "35px", xl: "74px", "2xl": "74px" }}
                                mb={{ base: "10px", xl: "35px", "2xl": "35px" }}
                              />
                              <Text
                                fontSize={{ base: "18px", xl: "42px", "2xl": "42px" }}
                                fontWeight={"700"}
                                lineHeight={{ base: "25px", xl: "54px", "2xl": "54px" }}
                                color={"primaryYellow.100"}
                              >
                                A Digital Oasis for the Modern Muslim Lifestyle
                              </Text>
                            </Box>
                          </Stack>
                        </Container>
                      </Box>
                    </SwiperSlide>
                )
              })
            }
          </Swiper>
        </Container >
      </Box >
      <Box bgImage={BlackOver}
        display={{ base: 'block', xl: 'none' }}
      >
        <Container
          maxW={{ base: "1366px", '2xl': "1440px" }}>
          <Swiper
            ref={swiperRef2}
            slidesPerView={1}
            spaceBetween={"20px"}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            loop={true}
            breakpoints={{
              375: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1366: {
                slidesPerView: 1,
                spaceBetween: 0,
              }
            }}
          >
            {
              marItem.map((val, key) => {
                return (
                  banners?.length > 0 ?
                    banners?.map(val =>
                      <SwiperSlide className="desktopView" key={val?._id}>
                        <Box
                          bg={"#28551A"}
                          bgImage={`${imgUrl}/${val?.mobile_banner}`}
                          bgPosition={"center"}
                          bgRepeat={"no-repeat"}
                          bgSize={"cover"}
                          borderRadius={"10px"}
                          height={{ base: "180px", xl: "280px", '2xl': "320px" }}
                        ></Box>
                      </SwiperSlide>
                    )
                    :
                    < SwiperSlide key={key} >
                      <Box
                        bg={"#28551A"}
                        bgImage={homeBg}
                        bgPosition={"center"}
                        bgRepeat={"no-repeat"}
                        bgSize={"cover"}
                        borderRadius={"10px"}
                        height={{ base: "180px", xl: "280px", '2xl': "320px" }}
                      >
                        <Container maxW={"container.xl"}>
                          <Stack
                            h={{ base: "210px", xl: "510px", "2xl": "510px" }}
                            direction={"row"}
                            alignItems={{ base: "center", xl: "end", "2xl": "end" }}
                            justifyContent={{ base: "flex-end", xl: "flex-end", "2xl": "flex-end" }}
                            pb={{ base: "0px", xl: "40px", "2xl": "40px" }}
                          >
                            <Box maxW={"645px"}>
                              <Text
                                fontSize={{ base: "14px", xl: "26px", "2xl": "26px" }}
                                fontWeight={"700"}
                                lineHeight={"normal"}
                                color={"primaryYellow.100"}
                                mb={{ base: "10px", xl: "15px", "2xl": "15px" }}
                              >
                                Enjoy Halal Lifestyle with us
                              </Text>
                              <Image
                                src={herotext}
                                w={{ base: "200px", xl: "402px", "2xl": "402px" }}
                                h={{ base: "35px", xl: "74px", "2xl": "74px" }}
                                mb={{ base: "10px", xl: "35px", "2xl": "35px" }}
                              />
                              <Text
                                fontSize={{ base: "18px", xl: "42px", "2xl": "42px" }}
                                fontWeight={"700"}
                                lineHeight={{ base: "25px", xl: "54px", "2xl": "54px" }}
                                color={"primaryYellow.100"}
                              >
                                A Digital Oasis for the Modern Muslim Lifestyle
                              </Text>
                            </Box>
                          </Stack>
                        </Container>
                      </Box>
                    </SwiperSlide>
                )
              })
            }
          </Swiper>
        </Container >
      </Box >

      <Box p={{ base: "10px 0px", xl: "40px 0px" }} bgImage={BlackOver}>
        <Container maxW={{ base: "1366px", '2xl': "1440px" }}>
          {
            categoryList.length > 0 &&
            <>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={{ base: "20px", xl: "40px" }}
              >
                <Text
                  fontSize={{ base: "16px", xl: "30px", "2xl": "30px" }}
                  fontWeight={"700"}
                  lineHeight={'normal'}
                  color={'primaryGreen.100'}
                >
                  What would you like to order?
                </Text>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  fontSize={{ base: "16px", xl: "30px", "2xl": "30px" }}
                  color={'primaryGreen.100'}
                >
                  <FaChevronLeft onClick={handlePrevCat} cursor={"pointer"} />
                  <FaChevronRight onClick={handleNextCat} cursor={"pointer"} />
                </Stack>
              </Stack>

              <Box mb={{ base: "10px", xl: "40px" }}>
                <Swiper
                  ref={swiperRefCat}
                  slidesPerView={6}
                  spaceBetween={"20px"}
                  loop={true}
                  breakpoints={{
                    375: {
                      slidesPerView: 3,
                      spaceBetween: 0,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 2,
                    },
                    1366: {
                      slidesPerView: 6,
                      spaceBetween: 0,
                    }
                  }}
                >
                  {
                    categoryList?.length > 0 ?
                      categoryList?.map((val, key) => {
                        return (
                          <SwiperSlide key={val?._id}>
                            <OrderCard {...val} />
                          </SwiperSlide>
                        )
                      })
                      :
                      orderList.map((val, key) => {
                        console.log(categoryList)
                        return (
                          <SwiperSlide key={key}>
                            <OrderCard img={val.img} header={val.header} text={val.text} />
                          </SwiperSlide>
                        )
                      })
                  }
                </Swiper>
              </Box>
            </>
          }

          {/* <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={'40px'}
            flexWrap={"wrap"}
          >
            <Text
              fontSize={{ base: "16px", xl: "30px", "2xl": "30px" }}
              fontWeight={"700"}
              lineHeight={'normal'}
              color={'primaryGreen.100'}
            >
              Nearby Halal Options
            </Text>
            <Stack
              direction={"row"}
              alignItems={"center"}
              flexWrap={"wrap"}
            >
              {halalOption.map((val, key) => {
                return (
                  <Box
                    key={key}
                    w={{ base: "77px", xl: "140px", "2xl": "140px" }}
                    textAlign={"center"}
                    padding={'10px 0px'}
                    border={"1px solid transparent"}
                    color="primaryGreen.100"
                    borderRadius={"5px"}
                    transform={'skew(-8deg)'}
                    _hover={{
                      border: "1px solid #28551A",
                      color: "primaryGreen.100",
                      bg: "primaryYellow.100",
                      transition: "all 0.4s",
                      fontWeight: "600 ! important"
                    }}
                  >
                    <Link to={val.link}>
                      <Text
                        transform={'skew(8deg)'}
                        fontSize={{ base: "12px", xl: "15px", "2xl": "15px" }}
                      >
                        {val.name}
                      </Text>
                    </Link>
                  </Box>
                )
              })}
            </Stack>
          </Stack> */}

          {/* <Stack
            direction={"row"}
            alignItems={'center'}
            justifyContent={{ base: "center", xl: "space-between", "2xl": "space-between" }}
            mb={"40px"}
            flexWrap={"wrap"}
            gap={{ base: "10px" }}
          >
            {
              halalBox.map((val, key) => {
                return (
                  <Box
                    key={key}
                    position={'relative'}
                    backgroundImage={val.bgImg}
                    width={{ base: "48%", xl: '310px', "2xl": '327px' }}
                    height={{ base: "235px", xl: '290px', "2xl": '290px' }}
                    borderRadius={"12px"}
                    m="0px ! important"
                  >
                    <Box
                      display={"flex"}
                      alignItems={"flex-end"}
                      padding={{ base: "20px 5px", xl: "25px 30px", "2xl": "25px 30px", }}
                      style={{ background: "linear-gradient(227deg, rgba(255, 255, 255, 0.00) 0.83%, rgba(3, 76, 2, 0.19) 48.71%, rgba(3, 76, 2, 0.79) 89.42%)", position: "absolute", zIndex: "1", width: "100%", height: "100%", borderRadius: "12px" }}

                    >
                      <Box
                        position={"absolute"}
                        top={"15px"}
                        right={"15px"}
                        bg={"primaryYellow.100"}
                        p={"5px 10px"}
                        borderRadius={"5px"}
                        transform={"skew(-12deg)"}
                      >
                        <Text
                          fontSize={{ base: "14px", xl: "15px" }}
                          fontWeight={"500"}
                          color={"#28551A"}
                          transform={"skew(12deg)"}
                        >
                          {val.discount}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize={{ base: "15px", xl: "17px" }} fontWeight={"500"} color={"#FC8A06"}>{val.head}</Text>
                        <Text fontSize={{ base: "18px", xl: "23px" }} fontWeight={"700"} color={"#FFF"}>{val.para}</Text>
                      </Box>
                    </Box>
                  </Box>
                )
              })
            }

          </Stack> */}

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={{ base: "10px", xl: "40px" }}
          >
            <Text
              fontSize={{ base: "16px", xl: "30px", "2xl": "30px" }}
              fontWeight={"700"}
              lineHeight={'normal'}
              color={'primaryGreen.100'}
            >
              Key Features Of Aimhalal App
            </Text>
            <Stack
              direction={"row"}
              alignItems={"center"}
              fontSize={{ base: "16px", xl: "30px", "2xl": "30px" }}
              color={'primaryGreen.100'}
            >
              <FaChevronLeft onClick={handlePrev} cursor={"pointer"} />
              <FaChevronRight onClick={handleNext} cursor={"pointer"} />
            </Stack>
          </Stack>

          <Box mb={{ base: "10px", xl: "40px" }}>
            <Swiper
              ref={swiperRef}
              slidesPerView={6}
              spaceBetween={"80px"}
              loop={true}
              breakpoints={{
                375: {
                  slidesPerView: 2,
                  spaceBetween: 1,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 1,
                },
                1366: {
                  slidesPerView: 3,
                  spaceBetween: 1,
                }
              }}
            >
              {
                appFeatureInfo.map((val, ind) =>
                  <SwiperSlide>
                    <AppFeatureCard {...val} key={ind} index={ind} />
                  </SwiperSlide>
                )
              }
            </Swiper>
          </Box>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={{ base: "10px", xl: "40px" }}
          >
            <Box>
              <Text
                fontSize={{ base: "16px", xl: "30px", "2xl": "30px" }}
                fontWeight={"700"}
                lineHeight={'normal'}
                color={'primaryGreen.100'}
              >
                Upcoming Features
              </Text>
            </Box>
            <Stack
              direction={"row"}
              alignItems={"center"}
              fontSize={{ base: "16px", xl: "30px", "2xl": "30px" }}
              color={'primaryGreen.100'}
              display={{ base: 'flex', '2xl': 'none' }}
            >
              <FaChevronLeft onClick={handlePrev2} cursor={"pointer"} />
              <FaChevronRight onClick={handleNext2} cursor={"pointer"} />
            </Stack>
          </Stack>

          <Box>
            <Swiper
              ref={swiperRef2}
              slidesPerView={6}
              spaceBetween={{ base: "40px", xl: "20px" }}
              loop={true}
              breakpoints={{
                375: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 2,
                },
                1366: {
                  slidesPerView: 6,
                  spaceBetween: 0,
                }
              }}
            >
              {
                marItem.map((val, key) => {
                  return (
                    <SwiperSlide key={key}>
                      <Box margin={"20px auto 10px"} bg={'#e4e0dd'} width={{ base: "94%", xl: '175px', '2xl': "200px" }} borderRadius={"12px"} position={"relative"} >
                        <Box position={"absolute"} bg={"primaryYellow.100"} p={"5px 20px"} top={"-20px"} left={{ base: "15px", xl: "35px" }} borderRadius={"3px"} transform={"Skew(-8deg)"}><Text fontSize={"14px"} fontWeight={"500"} color={"#28551A"} transform={"Skew(8deg)"}>{val.status}</Text></Box>
                        <Box p={"15px 0px"}>
                          <Image src={val.img} width={{ base: "65px", xl: "65px" }} height={{ base: "65px", xl: "65px" }} m={"auto"} />
                        </Box>
                        <Box bg='#28551A' p={{ base: "15px 5px", xl: "15px 20px" }} borderRadius={"0px 0px 12px 12px"} textAlign={"center"}>
                          <Text fontSize={"15px"} fontWeight={"700"} color={"primaryYellow.100"}>{val.text}</Text>
                        </Box>
                      </Box>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </Box>
        </Container>
      </Box>

      <Box bgImage={BlackOver}
        transform={{ base: 'translateY(-30px)', xl: 'translateY(0px)' }}
        p={{ base: '10px 0px', xl: '30px 0px' }}>
        <Container maxW={"1440px"}>
          <Box
            mb={{ base: "15px", xl: "60px" }}>
            <HalalAdd />
          </Box>
          <Box>
            <Stack
              direction={{ base: "row", md: "row" }}
              alignItems={"center"}
              justifyContent={"space-between"}
              // flexWrap={"wrap"}
              gap={{ base: "10px" }}
            >
              {signData.map((val, key) => {
                return (
                  <SignCard
                    key={key}
                    img={val.img}
                    text1={val.text1}
                    text2={val.text2}
                    main={val.main}
                    btn={val.btn}
                    href={val.href}
                  />
                )
              })
              }
            </Stack>
          </Box>
        </Container>
      </Box>

      <Stack bgImage={BlackOver} transform={{ base: 'translateY(-30px)', xl: 'translateY(0px)' }}>
        <Container maxW={{ base: "1366px", '2xl': "1440px" }}>
          <Box bg={"primaryGreen.100"} bgImage={WhiteOver} my={{ base: "10px", xl: "50px" }} py={{ base: "10px", xl: "80px" }} px={{ base: "15px", xl: "40px" }}>
            <Stack direction={{ base: "column", xl: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={"20px"}>
              <Box w={{ base: '35%', '2xl': 'unset' }} display={{ base: 'none', xl: 'initial' }} position={"relative"}>
                <Image w={"472px"} h={"387px"} src={about1} />
                <Box position={"absolute"} bottom={"-35px"} right={"-180px"}>
                  <Image w={"303px"} h={"274px"} src={about2} />
                </Box>
              </Box>
              <Box
                m={{ base: "15px auto !important", xl: "0 0 0 auto !important" }}
                bgImage={BlackOver}
                bgColor={'#fff'}
                bgPos={'3px 14px'}
                borderRadius={"10px"}
                w={{ base: "95% !important", xl: "550px !important" }}
              >
                <Box
                  maxW={{ base: "auto !important", xl: "520px !important" }}
                  p={"30px 20px"}
                >
                  <Text fontSize={{ base: "24px", xl: "35px" }} fontWeight={"700"} color={"primaryGreen.100"} mb={"20px"}>Why Aimhalal?</Text>
                  <Text color={'primaryGreen.100'} fontSize={{ base: "14px", xl: "16px" }} fontWeight={'500'} mb={"15px"}>Elevate your lifestyle with Aimhalal! Your trusted source for accurate information on Halal Products and Services. Our advanced technology and direct verification, supported by a team of Muslim Researchers and the Canadian Muslim Community, bring you insights while upholding Islamic values. Revolutionize your Halal lifestyle with this powerful and user-friendly app.</Text>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Stack>

      <Box bgImage={BlackOver}>
        <Container maxW={"1440px"}>
          <Box
            bg={"primaryYellow.100"}
            bgImage={BlackOver}
            borderRadius={"12px"}
            p={{ base: "15px 5px", lg: "50px 35px", xl: "60px 70px" }}
          >
            <Stack
              direction={{ base: "column", xl: "row" }}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={"30px"}
            >
              <Text
                fontSize={{ base: "24px", md: "30px" }}
                fontWeight={"700"}
                color={'primaryGreen.100'}
              >
                Know more about us!
              </Text>
              <Stack
                direction={"row"}
                flexWrap={{ base: 'wrap', md: 'nowrap' }}
                justifyContent={{ base: 'space-between', md: 'flex-start' }}
                alignItems={"center"}
                spacing={0}
                gap={'10px'}
              >
                <Button
                  onClick={() => setKnowSelectedBtn(true)}
                  background={knowSelectedBtn ? 'primaryGreen.100' : 'transparent'}
                  color={knowSelectedBtn ? 'primaryYellow.100' : '#000'}
                  margin={'0 !important'}
                  w={"160px"}
                  p={'8px 10px'}
                  transform={"Skew(-10deg)"}
                  borderRadius={"5px"}
                  transition={'0.3s'}
                  _hover={{
                    bgColor: 'primaryGreen.100',
                    color: 'primaryYellow.100'
                  }}
                >
                  <Text as={"span"} fontSize={"15px"} transform={"Skew(10deg)"}>Why Choose Us?</Text>
                </Button>
                <Button
                  onClick={() => setKnowSelectedBtn(false)}
                  background={!knowSelectedBtn ? 'primaryGreen.100' : 'transparent'}
                  color={!knowSelectedBtn ? 'primaryYellow.100' : '#000'}
                  margin={'0 !important'}
                  w={"160px"}
                  p={'8px 10px'}
                  transform={"Skew(-10deg)"}
                  borderRadius={"5px"}
                  transition={'0.3s'}
                  _hover={{
                    bgColor: 'primaryGreen.100',
                    color: 'primaryYellow.100'
                  }}
                >
                  <Text as={"span"} fontSize={"15px"} transform={"Skew(10deg)"}>How It Works?</Text>
                </Button>
              </Stack>
            </Stack>
            <Box bg={"#28551A"} bgImage={WhiteOver} borderRadius={"12px"} py={'20px'}>
              <Stack mx="10px" direction={{ base: 'column', lg: "row" }} padding={{ base: '10px 0', md: '30px 0' }} alignItems={'center'}>
                {
                  knowSelectedBtn ?
                    <>
                      <Box flex={1} p={{ base: "10px", xl: "25px" }} color={'primaryYellow.100'}>
                        <Heading mb={{ base: "15px", xl: "30px" }} fontSize={'22px'} fontWeight={'600'}>Why Choose Us?</Heading>
                        <Text mb="20px" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}>Choose Aimhalal for your Halal needs - your one-stop solution. Our comprehensive services cater to all aspects of the Halal lifestyle. From sourcing products to providing valuable information, we are committed to making your experience convenient and satisfying. Join our community of satisfied users who trust us as their Halal partner.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Easy Settings: Customize according to your preferences effortlessly</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Fast & Authentic: Access to the Halal Food anytime and anywhere with immediate processing.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Community Bond: Connect with your fellow Muslim neighbours for meaningful interactions.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Secure & Halal Food: Making sure that the food is Halal is our responsibility and should be provided to you with a secure and easy method. </Text>
                      </Box>
                      <Box flex={2} display={{ base: 'none', xl: 'flex' }} justifyContent={'space-around'} alignItems={'center'}>
                        {
                          chooseBtn.map(val =>
                            <Stack key={val.name} className="btn" alignItems={'center'} justifyContent={'center'}>
                              <Button
                                w={'180px'}
                                h="182px"
                                gap={'20px'}
                                flexDir={'column'}
                                bgColor={'primaryYellow.100'}
                              >
                                <Text as={'span'} color={'primaryGreen.100'}>{val.name}</Text>
                                <Image display={'block'} w="87px" h="87px" src={val.img} marginX={'auto'} />
                              </Button>
                            </Stack>
                          )
                        }
                      </Box>
                    </>
                    :
                    <>
                      <Box flex={1} p={{ base: "10px", xl: "25px" }} color={'primaryYellow.100'}>
                        <Heading mb={{ base: "15px", xl: "30px" }} fontSize={'22px'} fontWeight={'600'}>How It Works?</Heading>
                        <Text mb="20px" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}>Discovering the world of Halal Aimhalal never been easier with Aimhalal App. Here's how it works:</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Install App: Download the Aimhalal App from your app store.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Register Account: Create your account to unlock a world of Halal options.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Explore Halal Restaurants: Find the best Halal dining experiences near you.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Discover Halal Events: Stay updated on exciting Halal events happening around you.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Locate Halal Products: Get information on all Halal product options in your vicinity.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Halal Food Directory: Navigate through our comprehensive directory for a diverse range of Halal food choices.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Connect with Muslim Community: Forge connections with your local Muslim community.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Share with AimHalal: Share your Halal discoveries and experiences with the AimHalal community.</Text>
                        <Text mb="10px !important" color={'primaryYellow.100'} fontSize={{ base: '13px', xl: '15px' }}><Icon mr="5px" as={FaCheckCircle} />Give Your Feedback: Your feedback matters! Help us enhance your Halal journey.</Text>
                      </Box>
                      <Box flex={2} flexWrap={'wrap'} display={{ base: 'none', xl: 'flex' }} justifyContent={'space-around'} alignItems={'center'}>
                        {
                          howBtn.map((val, ind) =>
                            <Stack marginLeft={ind > 3 ? '-56px' : '0px'} mb="20px" key={val.name} className="btn" alignItems={'center'} justifyContent={'center'}>
                              <Button
                                w={'180px'}
                                h="182px"
                                gap={'20px'}
                                flexDir={'column'}
                                bgColor={'primaryYellow.100'}
                              >
                                <Text as={'span'} color={'primaryGreen.100'}>{val.name}</Text>
                                <Image display={'block'} w="87px" h="87px" src={val.img} marginX={'auto'} />
                              </Button>
                            </Stack>
                          )
                        }
                      </Box>
                    </>
                }
              </Stack>
            </Box>
          </Box>
          <Box p={"30px 0px"}>
            {/* <CounterBox /> */}
          </Box>
        </Container >
      </Box >

      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'sm', md: 'xl' }}>
        <ModalOverlay />
        <ModalContent
          textAlign={'center'}
          bgSize="cover"
          bgPos="center"
          bgColor={'primaryYellow.100'}
          backgroundImage={shapeb}
          p={{ base: "10px 10px", md: "20px 40px" }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <ModalHeader>
            <ModalCloseButton onClick={onClose} />
          </ModalHeader>
          <Image w="100px" marginX={'auto'} src={Logo} />
          <Heading color="primaryGreen.100" fontSize={{ base: '22px', md: '24px' }} m="20px 0">Aimhalal is available on Android & IOS!</Heading>
          <Text fontSize={{ base: '11px', md: '15px' }} color={'primaryGreen.100'} fontWeight={'500'}>We're excited to announce that our app is now available for both Adnroid and iOS devices! Whether you're using an Android or an iOS device, you can now enjoy our app with just one click. Don't miss out - press the button below get the app today. #aimhalal</Text>
          <Image
            mt="10px !important"
            src={qr}
            width={'120px'}
            height={'130px'}
          />
          <Stack direction={"row"} alignItems={"center"} justifyContent={'center'} gap={"10px"} mb={"20px"} mt={"10px"}>
            <Link to={"#"}><Image
              onClick={() => window.open('https://apps.apple.com/pk/app/aimhalal/id6446046649', '_blank')}
              src={Down1} w={"121px"} /></Link>
            <Link to={"#"}><Image
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.aimhalal.app', '_blank')}
              src={Down2} w={"121px"} /></Link>
          </Stack>
          {/* <form id="newsLetterPopForm">
            <Box position={"relative"} maxW={"440px"} transform={"skew(-10deg)"} mt={"30px"}>
              <Input
                type="email"
                bg={"#D9D9D9"}
                w={"324px"}
                h={"auto"}
                p={"10px 15px"}
                fontSize={"13px"}
                name="email"
                placeholder="youremail@gmail.com"
                _placeholder={{ color: "rgba(0, 0, 0, 0.60);", fontSize: "13px", lineHeight: "36px", transform: "skew(10deg)" }}
                _focusVisible={{ boxShadow: "none", border: "none" }}
              />
              <Button
                isLoading={isLoading}
                onClick={submitNewsLetterPolicy}
                pos={"absolute"}
                h={"auto"}
                p={"12px 15px"}
                w={"150px"}
                bg={"#28551A"}
                bgImage={greenBtnTexture}
                bgSize="cover"
                bgPosition="center"
                bgRepeat="no-repeat"
                borderRadius={"5px"}
                color={"primaryYellow.100"}
                right={"0px"}
                zIndex={"1"}
                border={"1px solid transparent"}
                _hover={{ bg: "#D9D9D9", color: "#28551A", border: "1px solid #28551A" }}
              >
                <Text
                  as={'span'}
                  fontSize={"15px"}
                  fontWeight={"500"}
                  transform={"skew(10deg)"}
                >
                  Subscribe
                </Text>
              </Button>
            </Box>
          </form>
          <Text mt="15px" fontSize={'14px'} fontWeight={'600'}>If you have any questions, please contact us!</Text>
          <Text as={'a'} href="mailto:connect@aimhalal.com" m="10px 0px" fontSize={'15px'} fontWeight={'600'} textDecor={'underline !important'} color={'primaryGreen.100'}>connect@aimhalal.com</Text> */}
        </ModalContent>
      </Modal >

      <Footer />
    </>
  );
}