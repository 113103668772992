import React from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  useDisclosure,
  DrawerContent,
  BoxProps,
  FlexProps,
  Image,
  UnorderedList,
  ListItem,
  Collapse,
  Button,
  Text,
  Stack,
} from '@chakra-ui/react';
import {
  FiHome,
  FiCalendar,
  FiCompass,
  FiSettings,
  FiMenu,
} from 'react-icons/fi';
import { BsChat, BsPieChart, BsShop, BsCalendar2Event } from 'react-icons/bs';
import { BiCar, BiCycling, BiWallet } from 'react-icons/bi';
import { AiOutlineShop } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { HiOutlineSpeakerphone, HiOutlineUserGroup } from 'react-icons/hi';
import { Link as ReactLink } from 'react-router-dom';
import shapeb from '../../assets/images/icon/black.png'
import { IconType } from 'react-icons';
import { ReactText, useState } from 'react';
import logo from '../../assets/images/logo/logo.svg';
import { useNavigate } from 'react-router';
import { RiHome2Line, RiShoppingCartLine } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'
import { FaRegChartBar, FaBloggerB, FaRegUser, FaShapes } from 'react-icons/fa'
import { FiPlusCircle, FiHelpCircle } from 'react-icons/fi'
import { MdEventNote } from 'react-icons/md'
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg'
import greenBtnTexture from '../../assets/images/greenBtnTexture.svg'
import { useSelector } from 'react-redux';

export default function Sidenav({ children, type }) {

  const user = useSelector(state => state?.value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [navigationList, setNavigationList] = useState([
    {
      label: 'Home',
      icon: RiHome2Line,
      url: '/dashboard'
    },
    {
      label: 'Orders',
      icon: RiShoppingCartLine,
      url: '/dashboard/orders'
    },
    {
      label: 'My Store',
      icon: AiOutlineShop,
      url: '/dashboard/store'
    },
    {
      label: 'Menu',
      icon: FiPlusCircle,
      url: '/dashboard/menu'
    },
    {
      label: 'Promotion',
      icon: HiOutlineSpeakerphone,
      url: '/dashboard/promotion'
    },
    {
      label: 'Analytics',
      icon: FaRegChartBar,
      url: '/dashboard/analytics'
    },
    {
      label: 'Help & Contact',
      icon: FiHelpCircle,
      url: '/dashboard/help'
    },
    {
      label: 'Profile Settings',
      icon: FiSettings,
      url: '/dashboard/setting'
    },
    {
      label: 'Store Settings',
      icon: AiOutlineShop,
      url: '/dashboard/edit-store'
    },
    {
      label: 'Contest',
      icon: AiOutlineShop,
      url: '/dashboard/contest'
    },
  ]);

  const [superNavigationList, setSuperNavigationList] = useState([
    {
      label: 'Home',
      icon: RiHome2Line,
      url: '/super-dashboard'
    },
    {
      label: 'Store Request',
      icon: RiShoppingCartLine,
      url: '/super-dashboard/store-request'
    },
    {
      label: 'Partners',
      icon: HiOutlineUserGroup,
      url: '/super-dashboard/partners'
    },
    {
      label: 'Categories',
      icon: FaShapes,
      url: '/super-dashboard/categories'
    },
    {
      label: 'Users',
      icon: FaRegUser,
      url: '/super-dashboard/users'
    },
    {
      label: 'Riders',
      icon: BiCycling,
      url: '/super-dashboard/riders'
    },
    {
      label: 'Vehicle Requests',
      icon: BiCycling,
      url: '/super-dashboard/rider-vehicle-request'
    },
    {
      label: 'Rider Requests',
      icon: BiCycling,
      url: '/super-dashboard/rider-requests'
    },
    // {
    //   label: 'Promotion',
    //   icon: HiOutlineSpeakerphone,
    //   url: '/super-dashboard/promotion'
    // },
    {
      label: 'Events',
      icon: MdEventNote,
      url: '/super-dashboard/events'
    },
    {
      label: 'Blogs',
      icon: FaBloggerB,
      url: '/super-dashboard/blogs'
    },
    {
      label: 'Banners',
      icon: FaBloggerB,
      url: '/super-dashboard/banners'
    },
    {
      label: 'Analytics',
      icon: FaRegChartBar,
      url: '/super-dashboard/analytics'
    },
    {
      label: 'Help & Contact',
      icon: FiHelpCircle,
      url: '/super-dashboard/help'
    },
    {
      label: 'Setting',
      icon: FiSettings,
      url: '/super-dashboard/setting'
    },
  ]);
  const [superMasterNavigationList, setSuperMasterNavigationList] = useState([
    {
      label: 'Home',
      icon: RiHome2Line,
      url: '/super-dashboard'
    },
    {
      label: 'Store Request',
      icon: RiShoppingCartLine,
      url: '/super-dashboard/store-request'
    },
    {
      label: 'Partners',
      icon: HiOutlineUserGroup,
      url: '/super-dashboard/partners'
    },
    {
      label: 'Categories',
      icon: FaShapes,
      url: '/super-dashboard/categories'
    },
    {
      label: 'Users',
      icon: FaRegUser,
      url: '/super-dashboard/users'
    },
    // {
    //   label: 'Promotion',
    //   icon: HiOutlineSpeakerphone,
    //   url: '/super-dashboard/promotion'
    // },
    {
      label: 'Events',
      icon: MdEventNote,
      url: '/super-dashboard/events'
    },
    {
      label: 'Blogs',
      icon: FaBloggerB,
      url: '/super-dashboard/blogs'
    },
    {
      label: 'Banners',
      icon: FaBloggerB,
      url: '/super-dashboard/banners'
    },
    {
      label: 'Analytics',
      icon: FaRegChartBar,
      url: '/super-dashboard/analytics'
    },
    {
      label: 'Logs',
      icon: FaRegChartBar,
      url: '/super-dashboard/logs'
    },
    {
      label: 'Help & Contact',
      icon: FiHelpCircle,
      url: '/super-dashboard/help'
    },
    {
      label: 'Setting',
      icon: FiSettings,
      url: '/super-dashboard/setting'
    },
  ]);
  
  return (
    <>
      <Box
        minH={{ base: 'fit-content', lg: '100vh' }}
        bg={'primaryYellow.100'}
        position={{ base: 'absolute', lg: 'initial' }}
        zIndex={'1'}
        left={'40px'}
        top={'32px'}
        w={{ base: '0px', lg: 'auto' }}
        backgroundImage={shapeb}
      >
        <SidebarContent
          navigationList={
            user?.role?.name === 'masteradmin' ? superMasterNavigationList
              : user?.role?.name === 'superadmin' ? superNavigationList :
                navigationList
          }
          onClose={() => onClose}
          display={{ base: 'none', lg: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent
              navigationList={
                user?.role?.name === 'masteradmin' ? superMasterNavigationList
                  : user?.role?.name === 'superadmin' ? superNavigationList :
                    navigationList
              }
              onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav display={{ base: 'flex', lg: 'none' }} onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p={{ base: '0', md: '4' }}>
          {children}
        </Box>
      </Box>
    </>
  );
}

const SidebarContent = ({ navigationList, onClose, ...rest }) => {
  const { isOpen, onToggle } = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box
      bg={'primaryYellow.100'}
      w={{ base: 'full', lg: 72 }}
      pos="fixed"
      backgroundImage={shapeb}
      h="full"
      {...rest}
    >
      <Flex mt={'20px'} h="20" alignItems="center" justifyContent="flex-end">
        <Image alt={'Logo'} src={logo} draggable={false} w={'120px'} />
        <CloseButton
          color={'#fff'}
          display={{ base: 'flex', lg: 'none' }}
          onClick={onClose}
        />
      </Flex>

      <Stack pl={4} pt={'40px'} ml={'80px'}>
        <UnorderedList className="sidemenu" listStyleType={'none'}>
          {
            navigationList.map(val =>
              <ListItem key={val.label} mb={'5px !important'}>
                <Stack className="btn">
                  <Button
                    onClick={() => navigate(val.url)}
                    w={'200px'}
                    padding={'10px 20px'}
                    bgImage={location.pathname === `${val.url}` ? greenBtnTexture : 'none'}
                    bgPos={'center'}
                    bgSize={'cover'}
                    bgRepeat={'no-repeat'}
                    bg={location.pathname === `${val.url}` ? 'primaryGreen.100' : 'transparent'}
                    justifyContent={'flex-start'}
                    color={location.pathname === `${val.url}` ? '#fff' : '#000'}
                    fontWeight={'400'}
                    border={'2px solid'}
                    borderColor={location.pathname === `${val.url}` ? 'primaryGreen.100' : 'transparent'}
                    _hover={{
                      borderColor: 'primaryGreen.100'
                    }}
                  >
                    <Text as={'span'}>
                      <Icon as={val.icon} mr={'10px'} mb="-3px" />
                    </Text>
                    <Text as={'span'}>{val.label}</Text>
                  </Button>
                </Stack>
              </ListItem>
            )
          }
        </UnorderedList>
        {/* <UnorderedList className="sidemenu" listStyleType={'none'}>
          <ListItem mb={'5px !important'}>
            <Link as={ReactLink} to={'/'}>
              <Icon as={FiCompass} />
              Logout
            </Link>
          </ListItem>
        </UnorderedList> */}
      </Stack>
    </Box>
  );
};

const NavItem = ({ icon, url, children, ...rest }) => {
  const navigate = useNavigate();

  return (
    <Link
      onClick={() => navigate(url)}
      style={{ textDecoration: 'none', color: '#000' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: '#FFC728',
          color: '#000',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: '#000',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: '35px' }}
      px={{ base: 0, md: 0 }}
      height="auto"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="0px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
    </Flex>
  );
};
