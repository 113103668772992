import { Stack, Text, Box, Image } from "@chakra-ui/react";
import banner2 from '../../../assets/images/website/home/banner2.png';
import logo2 from '../../../assets/images/website/home/logo2.png';
import down1 from '../../../assets/images/website/home/down1.png';
import down2 from '../../../assets/images/website/home/down2.png';
import qr from '../../../assets/images/qr.jpg';
import BlackOver from '../../../assets/images/icon/black.png';
import iphone from '../../../assets/images/iphone.svg';
import googleStoreQr from '../../../assets/images/googleStoreQr.png';
import appStoreQr from '../../../assets/images/appStoreQr.png';

import { Link } from "react-router-dom";

const HalalAdd = () => {
    return (
        <>
            <Stack
                bg={"primaryYellow.100"}
                bgImage={BlackOver}
                height={{ base: "unset", xl: "500px", "2xl": "500px" }}
                alignItems={{ base: "center", xl: "flex-end", "2xl": "flex-end" }}
                borderRadius={'12px'}
                position={"relative"}
                p={{ base: "25px 0px" }}
            >
                <Box
                    position={"absolute"}
                    display={{ base: "none", xl: "block", "2xl": "block" }}
                    zIndex={"1"}
                    bottom={"0"}
                    left={"25px"}
                >
                    <Image
                        src={iphone}
                        width={"100%"}
                        height={"600px"}
                        transform={'translate(30%, 10%)'}
                    />
                </Box>
                <Box
                    p={{ base: "0px", xl: "100px 120px 0px 0px", "2xl": "100px 120px 0px 0px" }}
                    textAlign={{ base: "center", xl: "right", "2xl": "right" }}
                >
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={{ base: "center", xl: "right", "2xl": "right" }}
                        mb={"15px"}
                    >
                        {/* <Image
                            src={logo2}
                            width={{ base: "30px", xl: "80px", "2xl": "80px" }}
                            height={{ base: "30px", xl: "80px", "2xl": "80px" }}
                        /> */}
                        <Text fontSize={{ base: "26px", xl: '55px', "2xl": '55px' }} fontWeight={"700"} color={'primaryGreen.100'}>Aimhalal is more</Text>
                    </Stack>
                    <Box
                        bg={"#28551A"}
                        borderRadius={"110px"}
                        width={{ base: "330px", xl: "750px", "2xl": "900px" }}
                        p={"0px 25px"}
                        m={{ base: "auto" }}
                        textAlign={"right"}
                        mb={"20px"}
                    >
                        <Text
                            fontSize={{ base: "24px", xl: "45px", "2xl": "45px" }}
                            fontWeight={"500"}
                            color={"primaryYellow.100"}
                        >
                            <Text
                                as={"span"}
                            // textDecorationLine={"underline"}
                            >
                                Personalised
                            </Text>
                            {" "}
                            &  <Text
                                as={"span"}
                            // textDecorationLine={"underline"}
                            >
                                Instant
                            </Text>
                        </Text>
                    </Box>
                    <Box p={{ base: "0px", xl: "0px 40px", "2xl": "0px 40px" }}>
                        <Text
                            fontSize={{base:"14px", xl: "19px"}}
                            mb={"20px"}
                        >
                            Download the Aimhalal app for faster ordering
                        </Text>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={{ base: "center", xl: "right", "2xl": "right" }}
                            pr={{ base: "0px", xl: "60px", "2xl": "60px" }}
                            gap={{ base: "5px", xl: "20px", "2xl": "20px" }}
                        >
                            <Stack justifyContent={'center'} alignItems="center">
                                {/* <Image
                                    src={appStoreQr}
                                    objectFit="contain"
                                    width={{ base: "135px", xl: "150px", "2xl": "unset" }}
                                    height={{ base: "45px", xl: "80px", "2xl": "100px" }}
                                /> */}
                                <Image
                                    cursor={'pointer'}
                                    onClick={() => window.open('https://apps.apple.com/pk/app/aimhalal/id6446046649', '_blank')}
                                    src={down1}
                                    width={{ base: "115px", xl: "150px", "2xl": "150px" }}
                                    height={{ base: "35px", xl: "50px", "2xl": "50px" }}
                                />
                            </Stack>
                            <Stack justifyContent={'center'} alignItems="center">
                                {/* <Image
                                cursor={'pointer'}
                                    src={googleStoreQr}
                                    objectFit="contain"
                                    width={{ base: "115px", xl: "150px", "2xl": "unset" }}
                                    height={{ base: "35px", xl: "80px", "2xl": "100px" }}
                                /> */}
                                <Image
                                    cursor={'pointer'}
                                    onClick={() => window.open('https://play.google.com/store/apps/details?id=com.aimhalal.app', '_blank')}
                                    src={down2}
                                    width={{ base: "115px", xl: "150px", "2xl": "150px" }}
                                    height={{ base: "35px", xl: "50px", "2xl": "50px" }}
                                />
                            </Stack>

                        </Stack>
                    </Box>
                </Box>
                <Image
                    // display={{ base: 'none', lg: 'initial' }}
                    position={{ base: 'initial', xl: 'absolute' }}
                    src={qr}
                    right="10px"
                    bottom="10px"
                    width={{base: '80px', xl: '120px'}}
                    height={{base: '90px', xl: '130px'}}
                />
            </Stack>
        </>
    )
}

export default HalalAdd;