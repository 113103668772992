import { Box, Heading, Image, Text, VStack } from "@chakra-ui/react";

export const CriteriaCard = ({ image, title, description, color }) => (
    <Box 
    bg={color} 
    borderRadius="lg" 
    overflow="hidden" 
    boxShadow="md"
    maxW={{ base: "280px", md: "350px",xl: "400px" }}
    >
    <Image src={image} alt={title} w="100%" h={{ base: "230px", md: "300px",xl: "300px" }} objectFit="cover" />
    <VStack p={4} align="center" spacing={2} h="130px">
        <Heading size="md" color="primaryYellow.100" maxW="200px" textAlign="center">{title}</Heading>
        <Text color="white" fontSize="sm" maxW="300px" textAlign="center">{description}</Text>
    </VStack>
    </Box>
);